import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "../../css/Home/HomeVideo.css"

import loading from "../../store/actions/loading"; 

const HomeVideo = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let video = document.getElementById("Home_HomeVideoContent")
        video.oncanplaythrough = () => {
            props.ready("video")
            dispatch(loading.updateLoading("home_video", 1))
        } 
        
        
    }, [])

    return (
        <div id='Home_HomeVideo'>
            <video id='Home_HomeVideoContent' autoPlay muted playsInline width="100%" height="100%">
            {/*<video id='Home_HomeVideoContent' muted playsInline width="100%" height="100%">*/}
                <source src="/videos/test2.mp4" type="video/mp4"></source>
            </video>
        </div>
    )
}

export default HomeVideo;
