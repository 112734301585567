import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "../../css/Home/HomeTop.css";
import getScript from "../../language";

import loading from "../../store/actions/loading"; 

const HomeTop = (props) => {
    const language = useSelector(state => state.user.language);
    const dispatch = useDispatch();
    const script = getScript("home_top");
    const [loadedImg, setLoadedImg] = useState(0);
    const [langClass, setLangClass] = useState("Home_HomeTop_Title");


    function imgReady() {
        setLoadedImg(loadedImg + 1);
        dispatch(loading.updateLoading("home_images", 1));
    }

    useEffect(() => {
        if (loadedImg >= 4) {
            props.ready('top')
        }
    }, [loadedImg])

    useEffect(() => {
        language === "en" ? setLangClass("Home_HomeTop_Title Home_HomeTop_Title_en") : setLangClass("Home_HomeTop_Title") ;
    }, [language])
    return (
        <div id='Home_HomeTop'>
            <a href="/brand">
                <img src="/images/Home/Homepage1.jpg" onLoad={imgReady} alt="Home" />
                <div className={langClass} >{ script.brands }</div>
            </a>
            <a href="/about">
                <img src="/images/Home/Homepage2.jpg" onLoad={imgReady} alt="Home" />
                <div className={langClass} >{ script.about }</div>
            </a>
            <a href="product">
                <img src="/images/Home/Homepage3.jpg" onLoad={imgReady} alt="Home" />
                <div className={langClass} >{ script.products }</div>
            </a>
            <a href="contact">
                <img src="/images/Home/Homepage4.jpg" onLoad={imgReady} alt="Home" />
                <div className={langClass} >{ script.inquiry }</div>
            </a>
        </div>
    )
}

export default HomeTop;