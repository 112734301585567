import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import BrandTop from '../components/Brand/BrandTop.js';
import BrandMid from '../components/Brand/BrandMid.js';

import loading from "../store/actions/loading";

const Brand = (props) => {
    const dispatch = useDispatch();


    useEffect(() => {
        let loadingWrapper = document.getElementById("loadingWrapper");
        loadingWrapper.style.display = "flex";
        return () => {
            dispatch(loading.resetLoading());
            loadingWrapper.style.display = "none";
        }
    }, [])

    return (
        <div className='bodyContainer'>
            <BrandTop />
            <BrandMid />
            <img src="/images/all_brands.jpg" width="100%" height="100%" />
            
            {/*<iframe src="https://www.borgwarner.com/home" width="90%" height="100%"/>*/}
        </div>
    )
}

export default Brand;