const getScript = page => {
    let language = localStorage.getItem("language")
    if (!language) {
        language = "en"
    }
    let script = {
        header: {
            en: {
                name: "UNITECH AUTO",
                home: "HOME",
                about: "ABOUT",
                inquiry: "INQUIRY",
                product: "PRODUCTS",
                contact: "CONTACT"
            },
            ko: {
                name: "유니텍오토",
                home: "홈",
                about: "소개",
                inquiry: "문의",
                product: "제품",
                contact: "연락"
            }
        },
        mobile_menu: {
            en: {
                name: "UNITECH AUTO",
                home: "HOME",
                brand: "BRANDS",
                about: "ABOUT",
                inquiry: "INQUIRY",
                product: "PRODUCTS",
                contact: "CONTACT"
            },
            ko: {
                name: "유니텍오토",
                home: "홈",
                brand: "브랜드",
                about: "소개",
                inquiry: "문의",
                product: "제품",
                contact: "연락"
            }
        },
        footer: {
            en: {
                name: "Unitech Auto",
                language: "Change the language",
                address: "Location:",
                location: "58, Saemal-ro, Guro-gu, Seoul, Korea"
            },
            ko: {
                name: "유니텍오토",
                language: "언어 변경",
                address: "주소:",
                location: "서울 구로구 새말로 58, 3층 유니텍오토"
            }
        },
        home_top: {
            en: {
                brands: "BRANDS",
                about: "ABOUT",
                products: "PRODUCTS",
                inquiry: "INQUIRY"
            },
            ko: {
                brands: "브랜드",
                about: "소개",
                products: "제품",
                inquiry: "문의"
            }
        },
        home_mid: {
            en: {
                cover: "Discover Unitech Auto",
                cover_detail:
                    "The leader in trading industry and wholesale of automobile parts.",
                title1: "Largest distributor in South Korea",
                title2: "Specialized in commercial vehicle parts",
                title3: "Exclusive Direct Import",
                detail1:
                    "A large selection of auto spare parts for over 150 OE, OEM, OES manufactures with more than 12,000 products. ",
                detail2:
                    "Supply specialized spare parts for commercial vehicles, power plants, and militay use",
                detail3:
                    "Directly import original parts and OEM products from manufactures to offer high quality spare parts"
            },
            ko: {
                cover: "유니텍오토 주식회사",
                cover_detail:
                    "엔진에서 작은 볼트 하나까지 부품의 모든 것을 취급합니다.",
                title1: "국내 최대 상용차 부품 공급업체",
                title2: "부품 공급 회사",
                title3: "독점 국내 공급 계약",
                detail1:
                    "자동차 부품을 약 150여 사의 외국부품전문 생산업체로부터 다양한 브랜드 제품도 수입하여 공급해 드리고 있습니다.",
                detail2:
                    "수입 상용차 부품, 국산 상용차 부품, 선박엔진, 발전소 부품, 버스부품, 승용차 부품, 각종장비 부품 등을 취급하는 회사 입니다.",
                detail3:
                    "순정부품 및 OEM제품을 해외로부터 직수입하여 공급함으로 양질의 부품을 경쟁력 있는 가격으로 공급하고 있습니다."
            }
        },
        home_contact: {
            en: {
                wholesale: "Wholesale",
                phone: "Give us a call",
                mail: "Send us an email",
                location: "Visit us (Head office)",
                phone2: "(82)2-868-1154",
                mail2: "unitech-korea@hanmail.net",
                location2: "58, Saemal-ro, Guro-gu, Seoul, Korea",
                warehouse1_location: "Warehouse 1 location",
                warehouse1_location2: "70-2, Geumpo-ro 1491beon-gil, Gimpo-si, Gyeonggi-do, Korea",
                warehouse2_location: "Warehouse 2 location",
                warehouse2_location2: "947 Hanam-daero A-B112, Hanam-si, Gyeonggi-do, Korea",
            },
            ko: {
                wholesale: "도매 문의",
                phone: "전화 번호",
                mail: "메일 주소",
                location: "방문 주소 (본사)",
                phone2: "02-868-1154",
                mail2: "unitech-korea@hanmail.net",
                location2: "서울 구로구 새말로 58, 3층 유니텍오토",
                warehouse1_location: "창고 1 주소",
                warehouse1_location2: "경기도 김포시 금포로1491번길 70-2",
                warehouse2_location: "창고 2 주소",
                warehouse2_location2: "하남시 하남대로 947 하남테크노밸리 U1 센터 A동 B112호",
            }
        },
        about: {
            en: {
                leader:
                    "The leader in trading industry and wholesale of automobile parts.",
                goal:
                    "Our goal is finding the best quality products globally and supplying our customers with the best at after market price.",
                founded: "Founded in",
                year: "1994",
                founded_description1:
                    "Since its foundation in 1994, Unitech Auto has made every effort to provide customers with the highest quality products at the most competitive prices along with superior service.",
                founded_description2:
                    "We supply original parts and OEM products both domestically and internationally.",
                perfect: "Perfect Parts, Perfect Safety",
                perfect_description1:
                    'Throughout our 30 year history in the Korean Auto Industry, this has been the foundation of our company, and our employees have made this on their priority in every aspect of business. CEO of Unitech Auto Co, Kevin Lee has always kept in his mind on "Perfect Spare Parts, Perfect Safety".',
                quality: "Quality Assurance",
                quality_description1:
                    "Quality is Unitech Auto's other name. Our parts have been tested by our engineers and our partners institute, using the highest standards of quality tests and real-time driving tests.  We would like to be your driving partner and create beautiful and lasting memories on the road together.",
                over: "Over 12,000 products",
                from: "from 150+ companies",
                over_description1:
                    "Unitech Auto is the largest supply trading company of auto spare parts in Korea.  We carry a large selection of auto spare parts for over 150 vehicle manufactures, including Scania, Volvo, Man, Mercedes Benz, Iveco, Trago, Powerteck, TataDaewoo. We have supplied specialized spare parts for commercial vehicles, power plants and military use.  We directly import original parts and OEM (Original Equipment Quality) products from the manufactures and offer high quality auto parts at reasonable prices that result in our customer's satisfaction.  Our featured items are more than 12,000 products from over 150 companies.  These parts have been tested by our engineers and our partner's institute, using the highest standards of quality tests and real-time driving tests.  We also export auto parts which have been produced in Korea, developing a close cooperative relationship with selected comapines in Korea which possess advanced technology and experience."
            },
            ko: {
                leader:
                    "항상 고객과 함께하는 저희 유니텍오토 홈페이지 방문을 환영합니다.",
                goal:
                    "끊임없이 변화하는 시장에서 고객만족을 위해 엄선된 최고 품질의 부품으로 엔진소리가 들리는 모든 도로와 사업장에서 저희 유니텍오토 주식회사는 항상 함께하고 있습니다.",
                founded: "설립",
                year: "1994년",
                founded_description1:
                    "1994년 설립 이후, 유니텍오토는 최고의 서비스와 최고 품질의 제품을 가장 경쟁력 있는 가격으로 고객에게 제공하기 위해 최선을 다하고 있습니다.",
                founded_description2:
                    "당사는 국내 및 국제적으로 순정 부품 및 OEM 제품을 공급합니다.",
                perfect: "완벽한 부품, 완벽한 안전",
                perfect_description1:
                    "한국 자동차 산업에서 30년의 역사를 통해 이것이 우리 회사의 기반이었으며 우리 직원들은 이를 모든 비즈니스 측면에서 최우선 순위로 삼았습니다. 유니텍오토의 CEO인 Kevin Lee는 항상 완벽한 부품, 완벽한 안전을 염두에 두고 있습니다.",
                quality: "품질 보증",
                quality_description1:
                    "품질은 유니텍오토 주식회사의 최고의 가치이며 동 경영철학에 맞추어 전문가들에 의하여 엄선된 부품은 고객들의 완벽한 도로주행과 안전 그리고 도로 위에서의 아름다운 추억만들기에 당사가 언제나 늘 함께 동행하겠습니다.",
                over: "12,000개 이상의 제품",
                from: "150+ 생산업체",
                over_description1:
                    "유니텍오토는 국내 최대 자동차부품 공급업체입니다. Scania, Volvo, Man, Mercedes Benz, Iveco, Trago, Powerteck, TataDaewoo를 포함한 150개 이상의 차량 제조업체를 위한 다양한 자동차 예비 부품을 취급합니다. 상용차, 발전소 및 군수용 특수 예비 부품을 공급하고 있습니다. 제조사로부터 순정 부품 및 OEM(Original Equipment Quality) 제품을 직접 수입하여 고품질의 자동차 부품을 합리적인 가격으로 제공하여 고객이 만족하실 수 있습니다. 우리의 주요 품목은 150개 이상의 회사에서 12,000개 이상의 제품입니다. 이 부품은 최고 수준의 품질 테스트와 실시간 주행 테스트를 사용하여 당사 엔지니어와 파트너 연구소에서 테스트를 거쳤습니다. 또한 한국에서 생산된 자동차 부품을 수출하여 선진 기술과 경험을 보유한 한국의 선별된 회사와 긴밀한 협력 관계를 구축하고 있습니다."
            }
        },
        contact: {
            en: {
                title: "Submit a Request",
                name: "Name",
                email: "Email address",
                what: "What do you need help with?",
                subject: "Subject",
                select: "Select",
                inquiry: "Inquiry",
                account: "Account issues",
                bug: "Report a bug",
                other: "Other",
                message: "Message",
                submit: "Submit",
                love_to: "We'd love to hear from you.",
                contact: "Contact us",
                address: "unitech-korea@hanmail.net",
                err_name: "Please insert name.",
                err_email: "Please insert email",
                err_incorrect_email: "Please insert valid email address",
                err_what: "Please select option",
                err_message: "Please insert message",
                failed: "Failed to send an email, please try later.",
                sending: "Sending"
            },
            ko: {
                title: "문의",
                name: "이름",
                email: "이메일 주소",
                what: "카테고리",
                subject: "제목",
                select: "선택",
                inquiry: "제품 문의",
                account: "계정 문제",
                bug: "버그 신고",
                other: "다른 이유",
                message: "메세지",
                submit: "전송",
                love_to: "We'd love to hear from you.",
                contact: "Contact us",
                address: "unitech-korea@hanmail.net",
                err_name: "이름을 입력해주세요.",
                err_email: "이메일 주소를 입력해주세요.",
                err_incorrect_email: "정확한 이메일 주소를 입력해주세요.",
                err_what: "카테고리를 선택해주세요.",
                err_message: "메세지를 입력해주세요.",
                failed: "메일 전송이 실패하였습니다. 다시 전송해 주세요.",
                sending: "전송중"
            }
        },
        contact_succeed: {
            en: {
                title: "Thank you for contacting us.",
                text: "Message has been sent successfully",
                another: "Send another message",
                home: "Back to homepage"
            },
            ko: {
                title: "문의해주셔서 감사합니다.",
                text: "메세지를 전송하였습니다",
                another: "다른 메세지 보내기",
                home: "홈페이지로 돌아가기"
            }
        }
    }
    if (script[page][language]) {
        return script[page][language]
    } else {
        return script[page].en
    }
}

export default getScript
