import React, { useState } from 'react';
import "../../css/Contact/Contact.css";
import { useHistory } from "react-router-dom";
import getScript from "../../language";

const ContactLeft = (props) => {
    let script = getScript("contact");
    const history = useHistory()
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [what, setWhat] = useState("");
    const [message, setMessage] = useState("");
    const [err, setErr] = useState("");
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    function submitForm() {
        setSending(true);
        //validate form
        if (name === "" || !name) {
            setErr(script.err_name);
        } else if (email === "" || !email) {
            setErr(script.err_email);
        } else if (!validateEmail(email)) {
            setErr(script.err_incorrect_email);
        } else if (what === "select" || !what) {
            setErr(script.err_what);
        } else if (message === "" || !message) {
            setErr(script.err_message);
        } else {
            setErr("")
            fetch("/api/email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name,
                    email,
                    what,
                    message
                })
            })
                .then(res => {
                    console.log(res)
                    return res.json()
                })
                .then(res => {
                    console.log(res)
                    if (res.status === "succeed") {
                        //succeed
                        history.push("Contact_Succeed")
                        setSent(true)
                    } else {
                        setErr(script.failed)
                    }
                })
        }
        setSending(false);
    }
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    return (
        <div id='Contact_ContactLeft'>
            <div id="contact_us_left_title">{script.title}</div>
            <div className="contact_us_left_label">{script.name}</div>
            <input
                type="text"
                value={name}
                onChange={e => setName(e.currentTarget.value)}
            />
            <div className="contact_us_left_label">{script.email}</div>
            <input
                type="text"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
            />
            <div className="contact_us_left_label">{script.what}</div>
            <select
                value={what}
                onChange={e => setWhat(e.currentTarget.value)}
            >
                <option value="select">{script.select}</option>
                <option value="inquiry">{script.inquiry}</option>
                <option value="account">{script.account}</option>
                <option value="bug">{script.bug}</option>
                <option value="other">{script.other}</option>
            </select>
            <div className="contact_us_left_label">{script.message}</div>
            <textarea
                rows="7"
                value={message}
                onChange={e => setMessage(e.currentTarget.value)}
            />
            <div id="contact_us_left_err">{err}</div>
            {!sending ?
                (<button
                    id="contact_us_left_submit"
                    type="submit"
                    onClick={submitForm}
                >
                    {script.submit}
                </button>)
                :
                (<button
                    id="contact_us_left_sending"
                    type="submit"
                    disabled
                >
                    {script.sending}
                </button>)
            }
        </div>
    )
}

export default ContactLeft;