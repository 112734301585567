import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import cartReducer from './reducers/cart';
import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';

const rootReducer = combineReducers({
    cart: cartReducer,
    user: userReducer,
    loading: loadingReducer
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));
store.subscribe(() => {
    //console.log(`subscribe = ${store.getState()}`)
})

export default store;
