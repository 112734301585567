const initialState = {
    "home_video": 0,
    "home_images": 0,
    "about_images": 0,
    "brand_images": 0,
}

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UpdateLoading":
            return {
                ...state,
                [action.payload.target]: state[action.payload.target] + action.payload.number
            }
        case "ResetLoading":
            return initialState
        default:
            return state
    }
}

export default loadingReducer