import React from 'react';
import { Link } from "react-router-dom";
import getScript from "../language";
import "../css/Contact_Succeed.css";


const Contact_Succeed = (props) => {
    const script = getScript("contact_succeed");

    return (
        <div className='bodyContainer' id="Contact_SucceedWrapper">
            <div>
                <div id="Contact_Succeed_title">{script.title}</div>
                <div id="Contact_Succeed_text">{script.text}</div>
                <div>
                    <div id="Contact_Succeed_link_wrapper" ><Link id="Contact_Succeed_link" to="/contact">{script.another}</Link></div>
                    <div id="Contact_Succeed_link_wrapper" ><Link id="Contact_Succeed_link" to="/">{script.home}</Link></div>
                </div>
            </div>
            <img src="images/Home/Home5.jpg" width="250px" height="auto" />
        </div>
    )
}

export default Contact_Succeed;