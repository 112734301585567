const initialState = {
    language: undefined
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UpdateLanguage":
            return {
                ...state,
                language: action.payload.language
            }
        default:
            return state
    }
}

export default userReducer
