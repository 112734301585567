import React from 'react';
import "../../css/About/AboutTop.css"
import getScript from "../../language"


const AboutTop = (props) => {
    const script = getScript("about");
    return (
        <div id='About_AboutTop'>
            <div id='About_AboutTop_leader'>{script.leader}</div>
            <div id='About_AboutTop_goal'>{script.goal}</div>
        </div>
    )
}

export default AboutTop;