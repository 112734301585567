import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import "../css/layout/Loading.css";
import "../css/layout/Loading.scss";

import loading from "../store/actions/loading";

const Loading = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const loadingObj = useSelector(state => state.loading);
	const [page, setPage] = useState("/");

	useEffect(() => {
		setPage(location.pathname);
		document.getElementById("loading_percentageBar").style.width = `0%`;
	}, [location])


	useEffect(() => {
		if (page == "/") {
			document.getElementById("loading_percentageBar").style.width = `${loadingObj["home_video"] * 37 + loadingObj["home_images"] * 9}%`;
		} else if (page == "/about") {
			document.getElementById("loading_percentageBar").style.width = `${loadingObj["about_images"] * 50}%`;
		} else if (page == "/brand") {
			document.getElementById("loading_percentageBar").style.width = `${loadingObj["brand_images"] * 20}%`;
		}
	}, [loadingObj])

    return (
        <div id="loadingWrapper">
			<div className="loader">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div id="loading_percentageWrapper">
				<div id="loading_percentageBar"></div>
			</div>

        </div>
    )
}

export default Loading
