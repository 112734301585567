import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import "./css/layout/Layout.css";
import store from "./store/store";

import Loading from "./layout/Loading";
import Header from "./layout/Header";
import MobileMenu from "./layout/MobileMenu";
import Home from "./route/Home";
import About from "./route/About";
import Product from "./route/Product";
import Brand from "./route/Brand";
import Contact from "./route/Contact";
import Contact_Succeed from "./route/Contact_Succeed";
import Footer from "./layout/Footer";


function App() {
    const [language, setLanguage] = useState()

    function changeLanguage(language) {
        console.log(`App language changed to ${language}`)
        setLanguage(language)
    }

    useEffect(() => {

        console.log('App language state changed')
    }, [language])

    return (
        <Provider store={store}>
            <div className="App">
                <Router>
                    <Loading />
                    <Header />
                    <MobileMenu />
                    <Switch>
                        <Route exact path={"/"} component={Home} />
                        <Route path={"/about"} component={About} />
                        <Route path={"/contact"} component={Contact} />
                        <Route path={"/product"} component={Product} />
                        <Route path={"/brand"} component={Brand} />
                        <Route path={"/Contact_Succeed"} component={Contact_Succeed} />
                    </Switch>
                    <Footer changeLanguage={changeLanguage} />
                </Router>
            </div>
        </Provider>
    );
}

export default App;
