exports.updateLoading = (target, number) => {
    return {
        type: "UpdateLoading",
        payload: { target, number }
    }
}

exports.resetLoading = () => {
    return {
        type: "ResetLoading"
    }
}