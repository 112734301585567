import React from 'react';
import { GoLocation } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import getScript from "../../language";
import "../../css/Home/HomeContact.css";


const HomeContact = (props) => {
    const script = getScript("home_contact");

    return (
        <div id="Home_HomeContactWrapper">
            <div id="Home_HomeContact_title">
                {script.wholesale}
            </div>
            <ul id="Home_HomeContact_list">
                <li>
                    <FiPhone color="#2C5486" size="30px" />
                    <a href="tell:+821090231154">
                        <div>
                            {script.phone}
                        </div>
                        <div className="Home_HomeContact_data">
                            {script.phone2}
                        </div>
                    </a>
                </li>
                <li>
                    <HiOutlineMail color="#2C5486" size="30px" />
                    <a href="mailto:unitechkevin@hanmail.net">
                        <div>
                            {script.mail}
                        </div>
                        <div className="Home_HomeContact_data">
                            {script.mail2}
                        </div>
                    </a>
                </li>
                <li>
                    <GoLocation color="#2C5486" size="30px" />
                    <a href="https://map.kakao.com/link/map/Unitech%20Auto,37.5031,%20126.88469">
                        <div>
                            {script.location}
                        </div>
                        <div className="Home_HomeContact_data">
                            {script.location2}
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default HomeContact;
