import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"


import getScript from "../language";
import "../css/layout/Header.css";


const Header = (props) => {
    const script = getScript("header")


    function showMenu() {
        let mobileMenu = document.getElementById('mobileMenuContainer');
        mobileMenu.style.marginLeft = "0%";
        setTimeout(() => {
            mobileMenu.style.backgroundColor = "rgb(158 158 156 / 60%)";
        }, 300)
    }
    return (
        <div>
            <div id='headerContainer'>
                <div id="headerLogo">
                    <Link to="/">{script.name}</Link>
                </div>
                <div id="headerNav">
                    <Link to="/">{script.home}</Link>
                    <Link to="/about">{script.about}</Link>
                    <Link to="/product">{script.product}</Link>
                    <Link to="/contact">{script.contact}</Link>
                </div>
                <div id="headerMobileIcon">
                    <AiOutlineMenu
                        className="header_mobileMenu"
                        size={30}
                        color={"black"}
                        onMouseEnter={() => {
                            //changeColorIn(4)
                        }}
                        onMouseLeave={() => {
                            //changeColorOut(4)
                        }}
                        onClick={showMenu}
                    />
                </div>
            </div>
            <div id="headerBottomLine"></div>
        </div>
    )
}

export default Header;