import React from 'react';

const HomeBot = (props) => {

    function imgReady() {
        props.ready('bot')
    }


    return (
        <div id='Home_HomeBot'>
            <img src="/images/all_brands.jpg" width="100%" onLoad={imgReady} alt="Home" />
        </div>
    )
}

export default HomeBot;