import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import "../../css/About/AboutBot.css";
import getScript from "../../language";

import loading from "../../store/actions/loading"; 


const AboutBot = (props) => {
    const language = useSelector(state => state.user.language);
    const dispatch = useDispatch();
    const script = getScript("about");
    const [imgReady, setImgReady] = useState(0);


    function imgLoaded() {
        setImgReady(imgReady + 1)
        dispatch(loading.updateLoading("about_images", 1))
    }

    useEffect(() => {
        let loadingWrapper = document.getElementById("loadingWrapper")
        loadingWrapper.style.display = "flex";
    }, [])

    useEffect(() => {
        if (imgReady >= 2) {
            let loadingWrapper = document.getElementById("loadingWrapper")
            loadingWrapper.style.display = "none";
        }
    }, [imgReady])

    return (
        <div id='About_AboutBot'>
            <div id="About_AboutBot_Row1">
                <div id="About_AboutBot_Row1_left">
                    {language == "en" ? 
                        <div id="About_AboutBot_Row1_left_line">{script.founded} <span id="About_AboutBot_Row1_left_year">{script.year}</span></div>
                        :
                        <div id="About_AboutBot_Row1_left_line"><span id="About_AboutBot_Row1_left_year">{script.year}</span> {script.founded}</div>
                    }
                    <div>{script.founded_description1}</div>
                    <div>{script.founded_description2}</div>
                </div>
                <div id="About_AboutBot_Row1_right">
                    <img className="About_AboutBot_img" src="/images/About/About1.jpg" onLoad={imgLoaded} alt="Founded Image" width="100%" />
                </div>
            </div>
            <div id="About_AboutBot_Row2">
                <div id="About_AboutBot_Row2_content">
                    <div id="About_AboutBot_Row2_content_perfect">{script.perfect}</div>
                    <div id="About_AboutBot_Row2_content_description">{script.perfect_description1}</div>
                </div>
            </div>
            <div id="About_AboutBot_Row3">
                <div id="About_AboutBot_Row3_left">
                    <img className="About_AboutBot_img" src="/images/About/About2.jpg" onLoad={imgLoaded} alt="Founded Image" width="100%" />
                    <div id="About_AboutBot_Row3_left_quality">{script.quality}</div>
                    <div id="About_AboutBot_Row3_left_description">{script.quality_description1}</div>
                </div>
                <div id="About_AboutBot_Row3_right">
                    <div className="About_AboutBot_Row3_right_title">{script.over}</div>
                    <div className="About_AboutBot_Row3_right_title">{script.from}</div>
                    <div id="About_AboutBot_Row3_right_description">{script.over_description1}</div>
                </div>
            </div>
        </div>
    )
}

export default AboutBot;