import React from 'react';
import ContactLeft from '../components/Contact/ContactLeft';
import ContactRight from '../components/Contact/ContactRight';
import ContactMap from '../components/Contact/ContactMap';


const Contact = (props) => {
    return (
        <div className='bodyContainer'>
            <div id='Contact_wrapper'>
                <ContactLeft />
                <ContactMap />
                {/*<ContactRight />*/}
            </div>
            
        </div>
    )
}

export default Contact;