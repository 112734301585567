import React, { useState, useEffect} from 'react';
import HomeVideo from "../components/Home/HomeVideo";
import HomeTop from "../components/Home/HomeTop";
import HomeMid from "../components/Home/HomeMid";
import HomeContact from "../components/Home/HomeContact";
import HomeBot from "../components/Home/HomeBot";
import { useDispatch } from 'react-redux';

import loading from "../store/actions/loading"; 

const Home = (props) => {
    const dispatch = useDispatch();
    const [videoStatus, setVideoStatus] = useState(false);
    const [topStatus, setTopStatus] = useState(false);
    const [midStatus, setMidStatus] = useState(false);
    const [botStatus, setBotStatus] = useState(false);


    function ready(what) {
        switch (what) {
            case "video":
                setVideoStatus(true)
                break;
            case "top":
                setTopStatus(true)
                break;
            case "mid":
                setMidStatus(true)
                break;
            case "bot":
                setBotStatus(true)
                break;
        }
    }

    useEffect(() => {
        let loadingWrapper = document.getElementById("loadingWrapper")
        loadingWrapper.style.display = "flex";
        return () => {
            dispatch(loading.resetLoading());
	        loadingWrapper.style.display = "none";
	    }
    }, [])

    useEffect(() => {
        if (videoStatus && topStatus && midStatus && botStatus) {
            let loadingWrapper = document.getElementById("loadingWrapper")
            loadingWrapper.style.display = "none";
            let video = document.getElementById("Home_HomeVideoContent")
            video.muted = true;
            video.play()
        }
    }, [videoStatus, topStatus, midStatus, botStatus])
    return (
        <div className='bodyContainer'>
            <HomeVideo ready={ready}/>
            <HomeTop ready={ready}/>
            <HomeMid ready={ready} />
            <HomeContact />
            <HomeBot ready={ready} />
        </div>
    )
}

export default Home;
