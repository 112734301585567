import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../css/Brand/BrandTop.css";

import loading from "../../store/actions/loading";


const BrandTop = (props) => {
    const loadedImages = useSelector(state => state.loading["brand_images"]);
    const dispatch = useDispatch();
    const [cur, setCur] = useState(0);


    function imgLoaded() {
        dispatch(loading.updateLoading("brand_images", 1))
    }

    useEffect(() => {
        let imageParent = document.getElementsByClassName("brandTop_img");
        for (let i = 0; i < imageParent.length; i++) {
            if (imageParent[i] && imageParent[i].complete) {
                imgLoaded()
            } else {
                imageParent[i].onload = () => {
                    imgLoaded()
                }
            }
        }

        let count = 0;
        let changeImage = setInterval(() => {
            let target = document.getElementsByClassName("brandTop_img");
            target[count].style.opacity = 0;
            if (count >= 4) {
                target[0].style.opacity = 100;
                count = 0;
            } else {
                target[count+1].style.opacity = 100;
                count++
            }
        }, 4000)

        return () => {
            clearInterval(changeImage);
            dispatch(loading.resetLoading())
        }
    }, [])

    useEffect(() => {
        if (loadedImages >= 5) {
            let loadingWrapper = document.getElementById("loadingWrapper")
            loadingWrapper.style.display = "none";
        }
        
    }, [loadedImages])
    return (
        <div id="brandTop">
            <img className="brandTop_img" style={{ objectFit: "contain" }} src="images/Brands/edited/knorr.png" width="90%" height="auto" />
            <img className="brandTop_img" style={{ opacity: "0", objectFit: "contain" }} src="images/Brands/edited/dayco.png" width="90%" height="auto" />
            <img className="brandTop_img" style={{ opacity: "0", objectFit: "contain" }} src="images/Brands/edited/mahle.png" width="90%" height="auto" />
            <img className="brandTop_img" style={{ opacity: "0", objectFit: "contain" }} src="images/Brands/edited/sachs.png" width="90%" height="auto" />
            <img className="brandTop_img" style={{ opacity: "0", objectFit: "contain" }} src="images/Brands/edited/wabco.png" width="90%" height="auto" />
            
        </div>
    )
}

export default BrandTop;