import React, { useState, useEffect } from 'react';
import getScript from "../../language";
import "../../css/Home/HomeMid.css";
import { useDispatch } from 'react-redux';

import loading from "../../store/actions/loading"; 


const HomeMid = (props) => {
    const script = getScript("home_mid");
    const dispatch = useDispatch();

    const [loadedImg, setLoadedImg] = useState(0)


    function imgReady() {
        setLoadedImg(loadedImg + 1)
        dispatch(loading.updateLoading("home_images", 1))
    }

    useEffect(() => {
        if (loadedImg >= 3) {
            props.ready('mid')
        }
    }, [loadedImg])

    return (
        <div id='Home_HomeMid'>
            <div id="Home_HomeMid_Cover">{script.cover}</div>
            <div id="Home_HomeMid_Cover_detail">{script.cover_detail}</div>
            <div id="Home_HomeMid_details">
                <div id="Home_HomeMid_details_left">
                    <img className="Home_HomeMid_images Home_HomeMid_images_left" src="/images/Home/Home5.jpg" onLoad={imgReady} alt="Home" />
                    <div className="Home_HomeMid_details_textContainer Home_HomeMid_details_textContainer_left">
                        <h4>01</h4>
                        <div>
                            <h4>{script.title1}</h4>
                            <p>{script.detail1}</p>
                        </div>
                    </div>
                </div>
                <div id="Home_HomeMid_details_right">
                    <img className="Home_HomeMid_images Home_HomeMid_images_right" src="/images/Home/Home6.jpg" onLoad={imgReady} alt="Home" />
                    <div className="Home_HomeMid_details_textContainer Home_HomeMid_details_textContainer_right">
                        <h4>02</h4>
                        <div>
                            <h4>{script.title2}</h4>
                            <p>{script.detail2}</p>
                        </div>
                    </div>
                    <img className="Home_HomeMid_images Home_HomeMid_images_right" src="/images/Home/Home7.jpg" onLoad={imgReady} alt="Home" />
                    <div className="Home_HomeMid_details_textContainer Home_HomeMid_details_textContainer_right">
                        <h4>03</h4>
                        <div>
                            <h4>{script.title3}</h4>
                            <p>{script.detail3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeMid;