import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AboutTop from "../components/About/AboutTop";
import AboutBot from "../components/About/AboutBot";

import loading from "../store/actions/loading";

const About = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let loadingWrapper = document.getElementById("loadingWrapper")
        loadingWrapper.style.display = "flex";

        return () => {
            dispatch(loading.resetLoading());
            loadingWrapper.style.display = "none";
        }
    }, [])
    return (
        <div className='bodyContainer'>
            <AboutTop />
            <AboutBot />
        </div>
    )
}

export default About;