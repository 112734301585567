import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import getScript from "../language"
import { BiPhone } from "react-icons/bi"
import { AiOutlineMail } from "react-icons/ai"
import { IoLocationOutline } from "react-icons/io5"

import user from "../store/actions/user"

import "../css/layout/Footer.css"

const Footer = props => {
    const dispatch = useDispatch()
    const script = getScript("footer")
    const [lang_option, setLang_option] = useState()
    const [year, setYear] = useState()

    function setLanguage(e) {
        props.changeLanguage(e)
        localStorage.setItem("language", e)
        dispatch(user.updateLanguage(e))
        setLang_option(e)
        let elements = document.getElementById("headerNav").children
        if (e == "ko") {
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.fontFamily = "'Noto Serif', serif"
                elements[i].style.fontSize = "18px"
            }
            document.head.querySelector(
                'meta[name="description"]'
            ).content = `유니텍오토`
            document.title = `유니텍오토`
        } else {
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.fontFamily = "Noto Serif, serif"
                elements[i].style.fontSize = "16px"
            }
            document.head.querySelector(
                'meta[name="description"]'
            ).content = `Unitech Auto`
            document.title = `Unitech Auto`
        }
    }

    useEffect(() => {
        let date = new Date()
        let year = date.getFullYear()
        setYear(year)

        try {
            let localStorageLanguage = localStorage.getItem("language")
            if (localStorageLanguage == undefined) {
                fetch("/api/language")
                    .then(val => {
                        console.log("fetch langauge")
                        return val.json()
                    })
                    .then(val => {
                        if (val.lang == "ko") {
                            setLanguage("ko")
                            localStorage.setItem("language", "ko")
                        } else {
                            setLanguage("en")
                            localStorage.setItem("language", "en")
                        }
                        //dispatch(user.updateLanguage(val.lang));
                    })
            } else {
                setLang_option(localStorageLanguage)
                dispatch(user.updateLanguage(localStorageLanguage))
            }
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <div>
            <div id="footerTopLine"></div>
            <div id="footerWrapper">
                <div id="footerLogo">
                    <Link to="/">{script.name}</Link>
                </div>
                <div id="footerMiddleWrapper">
                    <div id="footerMiddleTop">
                        <a href="tell:+8228681154">
                            <BiPhone /> 02-868-1154
                        </a>
                        <a href="mailto:unitech-korea@hanmail.net">
                            <AiOutlineMail />
                            unitech-korea@hanmail.net
                        </a>
                        <a href="https://map.kakao.com/link/map/Unitech%20Auto,37.5031,%20126.88469">
                            <IoLocationOutline /> {script.location}
                        </a>
                    </div>
                    <div>
                        Copyright © {year} Unitech Auto Inc. All rights
                        reserved.
                    </div>
                </div>

                <div className="languageSettingContainer">
                    <select
                        value={lang_option}
                        onChange={e => setLanguage(e.target.value)}
                    >
                        <option value="ko">Korean</option>
                        <option value="en">English</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default Footer
