import React from 'react';
import "../../css/Brand/BrandMid.css";



const BrandMid = (props) => {
    return (
        <div>
            <ul id="brandMid_ul">
                <li>Borgwarner</li>
                <li>Bosch</li>
                <li>Corteco</li>
                <li>Cummins</li>
                <li>Dayco</li>
                <li>Diesel Technic</li>
                <li>Dinex</li>
                <li>Eaton</li>
                <li>Elring</li>
                <li>Fag</li>
                <li>Febi</li>
                <li>Fleetguard</li>
                <li>Hengst</li>
                <li>Holset</li>
                <li>Iveco</li>
                <li>Jost</li>
                <li>Knorr-Bremse</li>
                <li>Kongsberg</li>
                <li>Lemforder</li>
                <li>Mahle</li>
                <li>Man</li>
                <li>Mercedes-Benz</li>
                <li>Man Filter</li>
                <li>Monroe</li>
                <li>Sachs</li>
                <li>Saf-Holland</li>
                <li>Scania</li>
                <li>SKF</li>
                <li>Valeo</li>
                <li>VDO</li>
                <li>Victor Reinz</li>
                <li>Volvo</li>
                <li>Wabco</li>
                <li>ZF</li>
            </ul>

        </div>
    )
}

export default BrandMid;