import React, { Component } from "react"
import "../../css/Contact/ContactMap.css"
import { GoLocation } from "react-icons/go"
import { FiPhone } from "react-icons/fi"
import { HiOutlineMail } from "react-icons/hi"
import getScript from "../../language"

const { kakao } = window

class ContactMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            scripts: getScript("home_contact")
        }
    }

    componentDidMount() {
        const script = document.createElement("script")
        script.async = true
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=060f8ddba9d1ccbaf301400fc2d590f1&autoload=false"
        document.head.appendChild(script)

        script.onload = async () => {
            var doit = await kakao.maps.load(() => {
                let el = document.getElementById("map")
                let map = new kakao.maps.Map(el, {
                    center: new kakao.maps.LatLng(37.5031, 126.88469)
                })

                //creating marker on the map
                var markerPosition = new kakao.maps.LatLng(37.5031, 126.88469)
                var marker = new kakao.maps.Marker({
                    position: markerPosition
                })
                marker.setMap(map)
                //creating markder description
                var iwContent =
                        '<div id="mapDescription">유니텍오토<br><a href="https://map.kakao.com/link/map/Unitech Auto,37.5031, 126.88469" target="_blank">큰지도보기</a> <a href="https://map.kakao.com/link/to/Unitech Auto,37.5031, 126.88469" target="_blank">길찾기</a></div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
                    iwPosition = new kakao.maps.LatLng(37.5031, 127.88469) //인포윈도우 표시 위치입니다

                var infowindow = new kakao.maps.InfoWindow({
                    position: iwPosition,
                    content: iwContent
                })
                infowindow.open(map, marker)
            })
            console.log(this.state.loading)
            this.setState({ loading: false })
            console.log(this.state.loading)
        }
    }

    render() {
        return (
            <div id="locationContainer">
                {/*
                <div id='locationCardContainer'>
                    <div className="locationCardDescriptions"><GoLocation color="white" size="20px" /><div>서울특별시 구로구 새말로31 롯데 111-302</div></div>
                    <div className="locationCardDescriptions"><FiPhone color="white" size="20px" /><div>02-868-1154</div></div>
                    <div className="locationCardDescriptions"><HiOutlineMail color="white" size="20px" /><div>kevin@unitechauto.co.kr</div></div>
                </div>
                */}

                <ul id="Contact_ContactMap_list">
                    <li>
                        <FiPhone color="#2C5486" size="30px" />
                        <a href="tell:+8228681154">
                            <div>{this.state.scripts.phone}</div>
                            <div className="Contact_ContactMap_data">
                                {this.state.scripts.phone2}
                            </div>
                        </a>
                    </li>
                    <li>
                        <HiOutlineMail color="#2C5486" size="30px" />
                        <a href="mailto:unitech-korea@hanmail.net">
                            <div>{this.state.scripts.mail}</div>
                            <div className="Contact_ContactMap_data">
                                {this.state.scripts.mail2}
                            </div>
                        </a>
                    </li>
                    <li>
                        <GoLocation color="#2C5486" size="30px" />
                        <a href="https://map.kakao.com/link/map/Unitech%20Auto,37.5031,%20126.88469">
                            <div>{this.state.scripts.location}</div>
                            <div className="Contact_ContactMap_data">
                                {this.state.scripts.location2}
                            </div>
                        </a>
                    </li>
                    <li>
                        <GoLocation color="#2C5486" size="30px" />
                        <a href="https://kko.to/n78CbEXYSo">
                            <div>{this.state.scripts.warehouse1_location}</div>
                            <div className="Contact_ContactMap_data">
                                {this.state.scripts.warehouse1_location2}
                            </div>
                        </a>
                    </li>
                    <li>
                        <GoLocation color="#2C5486" size="30px" />
                        <a href="https://kko.to/1ZGw-e9R7V">
                            <div>{this.state.scripts.warehouse2_location}</div>
                            <div className="Contact_ContactMap_data">
                                {this.state.scripts.warehouse2_location2}
                            </div>
                        </a>
                    </li>
                </ul>

                <div
                    className="map"
                    id="map"
                    style={{ width: "100%", height: "100%" }}
                ></div>
            </div>
        )
    }
}

export default ContactMap
