import React from 'react';
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";

import "../css/layout/MobileMenu.css";
import getScript from "../language";

const MobileMenu = (props) => {
    const script = getScript("mobile_menu");

    function closeMenu() {
        let mobileMenu = document.getElementById('mobileMenuContainer');
        mobileMenu.style.marginLeft = "100%";
        mobileMenu.style.backgroundColor = "transparent";
    }
    return (
        <div id="mobileMenuContainer">
            <div id="mobileMenu">
                <div id="mobileMenu_Top">
                    <div><Link id="mobileMenu_Top_Logo" to="" onClick={closeMenu}>{script.name}</Link></div>
                    <MdClose
                        className="header_mobileMenu"
                        id="mobileMenu_close"
                        size={30}
                        color={"white"}
                        onClick={closeMenu}
                    />
                </div>

                <div id="mobileMenu_Mid">
                    <Link to="/brand" className="mobileMenu_Link" onClick={closeMenu}>
                        <img src="images/MobileMenu/icon-03.svg" width="90px" height="90px"/>
                        <div id="mobileMenu_Link_Adjustment">{ script.brand }</div>
                    </Link>
                    <Link to="/about" className="mobileMenu_Link" onClick={closeMenu}>
                        <img src="images/MobileMenu/icon-05.svg" width="90px" height="90px"/>
                        <div>{script.about }</div>
                    </Link>
                    <Link to="/product" className="mobileMenu_Link" onClick={closeMenu}>
                        <img src="images/MobileMenu/icon-04.svg" width="90px" height="90px"/>
                        <div>{script.product}</div>
                    </Link>
                    <Link to="/contact" className="mobileMenu_Link" onClick={closeMenu}>
                        <img src="images/MobileMenu/icon-06.svg" width="90px" height="90px"/>
                        <div>{script.contact}</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;